import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MainPage = () => {
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [statistics, setStatistics] = useState({});
  const [selectedProtocols, setSelectedProtocols] = useState({});
  const [dateRange, setDateRange] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const apiUrl = `https://api.${window.location.hostname}/stats`;
    fetch(apiUrl)
        .then((res) => {
          if (!res.ok) {
            throw new Error('Network response was not ok');
          }
          return res.json();
        })
        .then((data) => {
          setStatistics(data);
        })
        .catch((error) => {
          console.error('Error fetching the API:', error);
        });
  }, []); // Empty dependency array ensures this runs only once

  const protocolColors = {
    "Anycast-based ICMPv4": "#4B8BAE",
    "Anycast-based TCPv4": "#E07B39",
    "Anycast-based UDPv4": "#A43BB0",
    "GCD-based ICMPv4": "#1C8731",
    "GCD-based TCPv4": "#D97A7A",
    "Anycast-based ICMPv6": "#A09E6E",
    "Anycast-based TCPv6": "#30D5C8",
    "Anycast-based UDPv6": "#9A3334",
    "GCD-based ICMPv6": "#F1C40F",
    "GCD-based TCPv6": "#3498DB",
  };
  const lastDate = Object.keys(statistics).length > 0 ? Object.keys(statistics).slice(-1)[0] : null;

  useEffect(() => {
    const initialSelection = Object.keys(protocolColors).reduce((acc, protocol) => {
      acc[protocol] = true;
      return acc;
    }, {});
    setSelectedProtocols(initialSelection);
  }, []); // Empty dependency array ensures this runs only once

  const filterDates = (range) => {
    const now = new Date();
    const dateLabels = Object.keys(statistics);
    switch (range) {
      case "7days":
        return dateLabels.filter(date => (now - new Date(date)) / (1000 * 60 * 60 * 24) <= 7);
      case "month":
        return dateLabels.filter(date => (now - new Date(date)) / (1000 * 60 * 60 * 24) <= 30);
      case "year":
        return dateLabels.filter(date => (now - new Date(date)) / (1000 * 60 * 60 * 24) <= 365);
      default:
        return dateLabels;
    }
  };

  useEffect(() => {
    if (Object.keys(statistics).length === 0) return;
    const labels = filterDates(dateRange);
    const datasets = Object.keys(protocolColors)
        .filter(protocol => selectedProtocols[protocol])
        .map(protocol => ({
          label: protocol,
          data: labels.map(date => statistics[date]?.[protocol] || 0),
          borderColor: protocolColors[protocol],
          backgroundColor: `${protocolColors[protocol]}80`,
          fill: false,
          pointRadius: 0,
          tension: 0.4,
        }));
    setChartData({
      labels: labels.map(date => new Date(date).toLocaleDateString()),
      datasets: datasets,
    });
  }, [selectedProtocols, dateRange, statistics]); // Removed protocolColors from dependency array

  const handleProtocolChange = (protocol) => {
    setSelectedProtocols(prevState => ({
      ...prevState,
      [protocol]: !prevState[protocol]
    }));
  };

  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = () => {
    if (searchQuery.trim() && lastDate) {
      navigate(`/app?date=${lastDate}&prefix=${searchQuery}`);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchSubmit();
    }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontFamily: "Arial, sans-serif",
      color: "#333",
      padding: "2%",
      maxWidth: "100%",
      margin: "auto",
      backgroundColor: "#f0f4f8",
    },
    title: {
      color: "#4B8BAE",
      fontSize: "1.5rem",
      marginBottom: "1rem",
      textAlign: "center",
    },
    sectionTitle: {
      fontSize: "1.2rem",
      color: "#4B8BAE",
      marginBottom: "0.5rem",
      textAlign: "center",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: "2rem",
    },
    th: {
      backgroundColor: "#4B8BAE",
      color: "#fff",
      padding: "0.5rem",
      fontWeight: "bold",
      border: "1px solid #ddd",
    },
    td: {
      padding: "0.5rem",
      textAlign: "left",
      border: "1px solid #ddd",
      backgroundColor: "#f9f9f9",
    },
    tdRight: {
      padding: "0.5rem",
      textAlign: "right",
      border: "1px solid #ddd",
      backgroundColor: "#f9f9f9",
    },
    chartLegendContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "2rem",
      backgroundColor: "#ffffff",
      padding: "2%",
      borderRadius: "0.5rem",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      width: "100%",
      flexGrow: 1,
    },
    chartContainer: {
      width: "100%",
      height: "100%",
      flexGrow: 1,
    },
    legend: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
      width: "100%",
      marginTop: "1rem",
    },
    legendItem: {
      display: "flex",
      alignItems: "center",
      borderRadius: "0.5rem",
      boxSizing: "border-box",
    },
    colorBox: (color) => ({
      width: "1rem",
      height: "1rem",
      backgroundColor: color,
      marginRight: "0.5rem",
      borderRadius: "50%",
    }),
    searchContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#F0F4F8",
      padding: "2%",
      borderRadius: "0.5rem",
      marginTop: "-2rem",
      width: "100%",
    },
    searchTitle: {
      fontSize: "1.5rem",
      color: "#2c3e50",
      marginBottom: "1rem",
      fontWeight: "bold",
      textAlign: "center",
    },
    searchInputContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      maxWidth: "600px",
    },
    searchInput: {
      padding: "1rem",
      fontSize: "1rem",
      width: "100%",
      borderTopLeftRadius: "0.5rem",
      borderBottomLeftRadius: "0.5rem",
      border: "1px solid #ddd",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    },
    searchButton: {
      padding: "1rem",
      backgroundColor: "#3498db",
      borderTopRightRadius: "0.5rem",
      borderBottomRightRadius: "0.5rem",
      border: "none",
      cursor: "pointer",
      color: "#fff",
      fontWeight: "bold",
    },
    dateRangeContainer: {
      display: "flex",
      justifyContent: "center",
      gap: "0.5rem",
      marginTop: "0.5rem",
    },
    dateRangeButton: {
      padding: "0.5rem 1rem",
      borderRadius: "0.5rem",
      border: "1px solid #ddd",
      backgroundColor: "#e9ecef",
      cursor: "pointer",
      fontWeight: "bold",
      color: "#333",
      transition: "background-color 0.3s, color 0.3s",
    },
    dateRangeButtonActive: {
      backgroundColor: "#4B8BAE",
      color: "#fff",
    },
    searchIcon: {
      width: "1rem",
      height: "1rem",
      verticalAlign: "middle",
    },
  };

  return (
      <div style={styles.container}>
        <div style={styles.searchContainer}>
          <h3 style={styles.searchTitle}>Check if an IP is Anycast</h3>
          <div style={styles.searchInputContainer}>
            <input
                type="text"
                placeholder="Enter an IPv4 or IPv6 address"
                style={styles.searchInput}
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
            />
            <button onClick={handleSearchSubmit} style={styles.searchButton}>
              <svg style={styles.searchIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path
                    d="M10 2a8 8 0 105.293 13.707l5.707 5.707a1 1 0 101.414-1.414l-5.707-5.707A8 8 0 0010 2zm0 2a6 6 0 110 12 6 6 0 010-12z"/>
              </svg>
            </button>
          </div>
        </div>
        <h2 style={styles.title}>Anycast Census Statistics on {date}</h2>

        <table style={styles.table}>
          <thead>
          <tr>
          <th style={styles.th}>Detection Mechanism</th>
            <th style={styles.th}>Anycast Prefixes (/24 for v4, /40 for v6)</th>
          </tr>
          </thead>
          <tbody>
          {Object.keys(statistics).length > 0 && lastDate
              ? Object.entries(statistics[lastDate]).map(([protocol, count]) => (
                  <tr key={protocol}>
                    <td style={styles.td}>{protocol}</td>
                    <td style={styles.tdRight}>{count}</td>
                  </tr>
              ))
              : <tr>
                <td colSpan="2">No data available</td>
              </tr>}
          </tbody>
        </table>
        <div style={styles.chartLegendContainer}>
          <h3 style={styles.sectionTitle}>Anycast Prefixes Over Time</h3>
          <div style={{width: "100%"}}>
            <Line
                data={chartData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: { display: false },
                    tooltip: {
                      enabled: true,
                      mode: 'index',
                      intersect: false,
                      position: 'nearest',
                      callbacks: {
                        label: function(context) {
                          let label = context.dataset.label || '';
                          if (label) {
                            label += ': ';
                          }
                          if (context.parsed.y !== null) {
                            label += context.parsed.y;
                          }
                          return label;
                        }
                      }
                    },
                    title: { display: false, text: "Anycast Prefixes Over Time" },
                  },
                  scales: {
                    x: { title: { display: true, text: "Date" } },
                    y: { title: { display: true, text: "Anycast Prefixes" } },
                  },
                }}
            />
          </div>
          <div style={styles.legend}>
            {Object.keys(protocolColors).map(protocol => (
                <div key={protocol} style={styles.legendItem}>
                  <input
                      type="checkbox"
                      checked={selectedProtocols[protocol]}
                      onChange={() => handleProtocolChange(protocol)}
                  />
                  <div style={styles.colorBox(protocolColors[protocol])}></div>
                  <label>{protocol}</label>
                </div>
            ))}
          </div>
          <div style={styles.dateRangeContainer}>
            {["7days", "month", "year", "all"].map(range => (
                <button
                    key={range}
                    onClick={() => handleDateRangeChange(range)}
                    style={{
                      ...styles.dateRangeButton,
                      ...(dateRange === range ? styles.dateRangeButtonActive : {})
                    }}
                >
                  {range === "7days" ? "Last 7 Days" : range === "month" ? "Last Month" : range === "year" ? "Last Year" : "All Time"}
                </button>
            ))}
          </div>
        </div>

      </div>
  );
};

export default MainPage;